import type { FedopsLogger as FedopsLoggerType } from '@wix/fe-essentials-editor';
import type { Interaction } from './types';

export class FedopsLogger {
  constructor(private fedops: FedopsLoggerType<Interaction>) {}

  menusShowcaseFirstInstallStarted = () => this.fedops.interactionStarted('menus-showcase-first-install');
  menusShowcaseFirstInstallEnded = () => this.fedops.interactionEnded('menus-showcase-first-install');

  openMenuElementsPanelStarted = () => this.fedops.interactionStarted('open-menu-elements-panel');
  openMenuElementsPanelEnded = () => this.fedops.interactionEnded('open-menu-elements-panel');

  openMenuSettingsPanelStarted = () => this.fedops.interactionStarted('open-menu-settings-panel');
  openMenuSettingsPanelEnded = () => this.fedops.interactionEnded('open-menu-settings-panel');

  openSettingsPanelStarted = () => this.fedops.interactionStarted('open-settings-panel');
  openSettingsPanelEnded = () => this.fedops.interactionEnded('open-settings-panel');

  setMenusDataStarted = () => this.fedops.interactionStarted('set-menus-data');
  setMenusDataEnded = () => this.fedops.interactionEnded('set-menus-data');

  loadMenusPageStarted = () => this.fedops.interactionStarted('load-menus-page');
  loadMenusPageEnded = () => this.fedops.interactionEnded('load-menus-page');
}
